/* eslint-disable no-empty */
/* eslint-disable eqeqeq */
import { InitSubApp } from '../SubApp';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  AmplifyApp,
  NavigationLink,
  Component,
  SubApp,
  UrlHistory,
  CoreNavigationLink,
  AmplifyWidget,
  CSSInfo,
  Version,
} from '@amplify/amplify-sdk';
import config from '../Config';
import axios, { AxiosInstance } from 'axios';
import packageJson from '../../package.json';
import AppDetails from '../components/AppDetails';
import { JwtToken } from './Types';

function loadConfig(scriptId: string, scriptSrc: string) {
  const script = document.createElement('script');
  script.id = scriptId;
  console.debug(`Loading Config: ${script.id}`);
  script.crossOrigin = '';
  script.src = scriptSrc;
  // if (script.id.includes('staff') || script.id.includes('bob'))
  script.type = 'module';
  document.head.appendChild(script);
}

export function loadAppConfigs(apps: any[]) {
  var cachebuster = Math.round(new Date().getTime() / 1000);
  apps.forEach(app => {
    const scriptId = `micro-frontend-config--${app.slug}`;
    const scriptSrc = `${process.env.REACT_APP_CDN_HOST}/apps/${app.slug}/static/js/amplifyConfig.js?v=${cachebuster}`;
    loadConfig(scriptId, scriptSrc);
  });
  var isPermissionRevoed = false;
  if (
    apps.filter(
      x =>
        x.name !== 'Reporting' &&
        x.name !== 'DevBuilder' &&
        x.name !== 'Constituent Snapshot' &&
        x.name !== 'Role Management'
    ).length == 0
  )
    isPermissionRevoed = true;
  if (apps.filter(x => x.accessRoleName == 'Amplify-User').length == 0) isPermissionRevoed = true;
  if (isPermissionRevoed) $componentApps.next([]);
}

var defaultVersions = [
  {
    version: AppDetails.version,
    commitHash: process.env.COMMITHASH,
    name: packageJson.name,
    author: 'Amplify',
    releaseDate: process.env.RELEASEDATE,
  } as Version,
];

const $amplifyApps = new BehaviorSubject<SubApp[]>([]);
const $widgetApps = new BehaviorSubject<AmplifyWidget[]>([]);
const $componentApps = new BehaviorSubject<Component[]>([]);
const $componentAppsLoaded = new BehaviorSubject<boolean>(false);
const $navigationItems = new BehaviorSubject<NavigationLink[]>([]);
const $coreNavigationItems = new BehaviorSubject<CoreNavigationLink[]>([]);
const $hasToken = new BehaviorSubject<boolean>(false);
const $versions = new BehaviorSubject<Version[]>(defaultVersions);

export let onAmplifyApps = () => $amplifyApps.asObservable() as Observable<SubApp[]>;
export let onWidgetApps = () => $widgetApps.asObservable() as Observable<AmplifyWidget[]>;
export let onNavigationItems = () => $navigationItems.asObservable() as Observable<NavigationLink[]>;
export let onComponentApps = () => $componentApps.asObservable() as Observable<Component[]>;
export let onComponentAppsLoaded = () => $componentAppsLoaded.asObservable() as Observable<boolean>;
export let onCoreNavigationItems = () => $coreNavigationItems.asObservable() as Observable<NavigationLink[]>;
export let onHasToken = () => $hasToken.asObservable() as Observable<boolean>;
export let onVersions = () => $versions.asObservable() as Observable<Version[]>;

export let urlHistory = new UrlHistory();

let amplifyToken: string;
let axiosInstance: AxiosInstance;

export function setOktaToken(newToken: string) {
  if (amplifyToken !== newToken) {
    amplifyToken = newToken;
    // Default config options
    const defaultOptions = {
      baseURL: `${config.amplifyApiHost}/v1/`,
      headers: {
        Accept: 'text/plain',
        'Content-Type': 'application/json-patch+json',
        client_id: `${config.amplifyClientId}`,
      },
    };

    // Create instance
    axiosInstance = axios.create(defaultOptions);

    // Set the AUTH token for any request
    axiosInstance.interceptors.request.use(function (axiosConfig: any) {
      axiosConfig.headers.Authorization = amplifyToken ? `Bearer ${amplifyToken}` : '';
      return axiosConfig;
    });
    window.amplify.axios = axiosInstance;
    window.amplify.token = amplifyToken;

    try {
      const jwt: JwtToken = JSON.parse(atob(amplifyToken.split('.')[1]));
      window.amplify.roles = jwt.Roles;
    } catch {}

    $hasToken.next(true);
  } else if (!newToken) {
    $hasToken.next(false);
  }
}

export function registerCoreNavigationItem(app: CoreNavigationLink) {
  const coreNavigationItems = $coreNavigationItems.getValue();
  if (coreNavigationItems.length == 0 || coreNavigationItems.filter(x => x.text === app.text).length == 0) {
    coreNavigationItems.push(app);
    $coreNavigationItems.next(coreNavigationItems);
  }
}

/* this is how we register the internal apps - to be deprecated*/
export function registerSubApp(app: SubApp) {
  console.debug(`Registering SubApp: ${app.slug}`);
  const amplifyApps = $amplifyApps.getValue();
  amplifyApps.push(app);
  $amplifyApps.next(amplifyApps);
}

function registerApp(module: AmplifyApp) {
  console.debug(`Registering App: ${module.slug}`);
  const amplifyApps = $amplifyApps.getValue();
  if (amplifyApps.length == 0 || amplifyApps.filter(x => x.slug === module.slug).length == 0) {
    amplifyApps.push(InitSubApp(module));
    $amplifyApps.next(amplifyApps);
  }
}

function registerWidget(widget: AmplifyWidget) {
  console.debug(`Registering Widget: ${widget.slug}`);
  const widgetApps = $widgetApps.getValue();
  widgetApps.push(widget);
  $widgetApps.next(widgetApps);
}

export function registerComponentApp(app: Component) {
  const componentApps = $componentApps.getValue();
  componentApps.push(app);
  $componentApps.next(componentApps);
  let componentLoad = $componentAppsLoaded.getValue();
  $componentAppsLoaded.next(!componentLoad);
}

export function registerNavigationItem(app: NavigationLink) {
  console.debug(`Registering NavigationItem: [${app.text}] ${app.link}`);
  const navigationItems = $navigationItems.getValue();
  if (navigationItems.length == 0 || navigationItems.filter(x => x.text === app.text).length == 0) {
    navigationItems.push(app);
    $navigationItems.next(navigationItems);
  }
}

export function registerVersion(version: Version) {
  const versions = $versions.getValue();
  versions.push(version);
  $versions.next(versions);
}

function loadCss(cssInfo: CSSInfo) {
  const { slug, moduleName } = cssInfo;
  const cssid = `micro-frontend-css--${slug}`;

  if (document.getElementById(cssid)) {
    return;
  }
  const host = `${process.env.REACT_APP_CDN_HOST}/apps/${slug}`;
  var cachebuster = Math.round(new Date().getTime() / 1000);

  const style = document.createElement('link');
  style.id = cssid;
  style.rel = 'stylesheet';
  style.href = `${host}/static/css/${moduleName}.css?v=${cachebuster}`;
  document.head.appendChild(style);
}

window.amplify = {
  ...(window.amplify ?? {}),
  registerWidget,
  registerApp,
  registerComponentApp,
  registerNavigationItem,
  registerCoreNavigationItem,
  registerVersion,
  onAmplifyApps,
  onWidgetApps,
  onNavigationItems,
  onCoreNavigationItems,
  onHasToken,
  onVersions,
  urlHistory,
  loadCss,
};
